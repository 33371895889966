<script>
import { IconNfc } from '@tabler/icons-vue';
import { mapGetters } from 'vuex';

import TemplateBase from '../../../molecules/Templates/TemplateBase';

export default defineNuxtComponent({
  components: { IconNfc, TemplateBase },
  props: {
    stepElement: {
      type: Object,
      required: false,
    },
    stepId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('FormTemplateModuleStore', ['formTemplateData']),
  },
  methods: {
    updateNfc(event) {
      this.stepElement.nfcId = event.target.__internalValue[0];
      this.stepElement.label = this.formTemplateData?.settings?.nfc.find(item => item.id === this.stepElement?.nfcId).value;

      this.$emit('changeOption', this.stepId);
    },
  },
});
</script>

<template>
  <TemplateBase
    :placeholder="__('NFC')"
    :element="stepElement"
    :step-id="stepId"
    :disable-label="stepElement?.nfcId"
  >
    <template #templateContent>
      <trailblazer-select
        :placeholder="__('Select a NFC device')"
        expand
        clearable
        @change="updateNfc($event)"
      >
        <trailblazer-option
          v-for="nfc in formTemplateData?.settings?.nfc"
          :key="nfc.id"
          :selected="nfc.id === stepElement?.nfcId"
          :value="nfc.id"
        >
          {{ nfc.value }} - {{ nfc.id }}
        </trailblazer-option>
      </trailblazer-select>
    </template>
  </TemplateBase>
</template>
